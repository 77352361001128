<script setup>
import { ref } from "vue"
import { UserOutlined } from '@ant-design/icons-vue';
import { useUserStore } from "@/store/user"
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';

const userStore = useUserStore();
const router = useRouter();

function logout() {
    userStore.logout();
    router.go(0);
}
</script>

<template>
    <header class="page-header">
        <view class="page-header-content">
            <img class="logo" src="@/assets/images/logo.png" @click="$router.push('/')" />

            <view class="page-header-right">
                <a-menu :selected-keys="[$route.path]" mode="horizontal">
                    <a-menu-item key="/" @click="$router.push('/')">
                        首页
                    </a-menu-item>
                    <a-menu-item key="/product" @click="$router.push('/product')">
                        卡券回收
                    </a-menu-item>
                    <a-menu-item key="app1" @click="message.warning('敬请期待~')">
                        代理商模式
                    </a-menu-item>
                    <a-menu-item key="app2" @click="message.warning('敬请期待~')">
                        联系我们
                    </a-menu-item>
                    <a-menu-item key="/center" @click="$router.push('/center')">
                        会员中心
                    </a-menu-item>
                </a-menu>

                <view class="passport" v-if="!userStore.getToken">
                    <view class="login" @click="$router.push('/login')">登录</view>
                    <view class="register" @click="$router.push('/register')">注册</view>
                </view>
                <div class="avatar" v-else>
                    
                    <!-- <a-popover v-model:visible="visible" :title="userStore.getUserInfo?.Account || '未知用户'" placement="bottomRight" trigger="click">
                        <template #content>
                            <a @click="userStore.logout()">退出登陆</a>
                        </template>
                        <a-avatar size="large">
                            <template #icon><UserOutlined /></template>
                        </a-avatar>
                    </a-popover> -->

                    <a-dropdown :trigger="['hover']">
                        <a-avatar size="large">
                            <template #icon><UserOutlined /></template>
                        </a-avatar>
                        <template #overlay>
                            <a-menu>
                                <a-menu-item key="0">
                                    <a @click="$router.push('/center')">个人中心</a>
                                </a-menu-item>
                                <a-menu-item key="1">
                                    <a @click="logout">退出登陆</a>
                                </a-menu-item>
                            </a-menu>
                        </template>
                    </a-dropdown>
                </div>
            </view>
        </view>
    </header>
</template>

<style lang="scss" scoped>
.page-header{
    padding: 0 32px;
    z-index: 999;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 3px 13px #0006;
    height: 64px;
    background: #e6796c;
    overflow-x: hidden;

    &-content {
        margin: auto;
        max-width: 1440px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-right {
        display: flex;
        align-items: center;
    }

    .logo {
        width: 140px;
        // height: 44px;
        cursor: pointer;
    }

    .ant-menu {
        height: 61px;
        background: transparent;
        color: #fff;
        
        &:before {
            display: none;
        }
    }

    .ant-menu-horizontal {
        border: none;
    }

    ::v-deep {
        .ant-menu-item {
            line-height: 3.7;
        }

        .ant-menu-item-selected {
            .ant-menu-title-content {
                color: #fff!important;
            }
            
            &::after {
                border-color: #fff!important;
            }
        }

        .ant-menu-item:hover {
            color: #fff!important; 

            &::after {
                border-color: #fff!important;
            }
        }

        .ant-menu-title-content {
            font-size: 16px;
        }
    }
}

.avatar{
    margin-left: 50px;
}

.passport {
    margin-left: 50px;
    display: flex;
    border: solid 1px white;
    border-radius: 30px;
    cursor: pointer;

    .login {
        padding: 0 26px;
        height: 28px;
        line-height: 2;
        background-color: white;
        color: #FF3D51;
        border-radius: 30px;
        
    }

    .register {
        padding: 0 14px;
        height: 28px;
        line-height: 2;
        // background-color: white;
        color: #fff;
        border-radius: 30px;
    }
}
</style>