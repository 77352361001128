import axios from 'axios'
import { message } from 'ant-design-vue';
import { useUserStore } from "@/store/user"
import { useRouter } from 'vue-router';

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// let baseUrl = ApiBaseUrl
let baseUrl = 'https://api.dev.szmitehuishou.com/'
// const userStore = useUserStore();
const router = useRouter();

// 创建
const request = axios.create({
    baseURL: baseUrl,
    timeout: 8000, // 超时时间
    //   withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
})
// 拦截请求
request.interceptors.request.use(config => {
    console.log("config:", config)
    if (config?.loading) {
        message.loading('Loading...');
    }

    const userStore = useUserStore();
    config.headers['Authorization'] = 'Basic ' + userStore.getToken || ''
    

    //   if (userStore.getUserInfo.token != undefined && userStore.getUserInfo.token !== '') {
    //     // config.headers.Authorization = userStore.getUserInfo.token
    //     if (!config.params) { // 放在哪里
    //       config.params = {}
    //     }
    //     // config.params['token'] = userStore.getUserInfo.token
    //   }

    // 获取请求头参数
    //   const { timestamp, signature } = signatureGenerate()
    //   if (timestamp) config.headers['tissuePaper'] = timestamp;
    //   if (signature) config.headers['sign'] = signature;
    return config
}, error => {
    Promise.reject(error)
})

// 拦截响应
request.interceptors.response.use(res => { // 200开头的
    if (res.config?.loading) {
        message.destroy()
    }

    const { Success, ErrorMsg } = res.data

    console.log("interceptors", res)
    if (Success) {
        return Promise.resolve(res.data)
    } else {
        message.error(ErrorMsg)
        return Promise.reject(res.data)
    }
}, error => {
      if (error.code == 'ERR_NETWORK') {
        message.error('ERR_NETWORK')
      } else if (error.code == 'ECONNABORTED') {
        message.error('Network Timeout')
      } else if (error && error.response) {
        if(error.response.status == 401) {
            // 需要登陆
            message.error("请先登陆")
            router.replace('/login')
        }
      }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(error);
}
)

export default request
