import request from "@/axios";

// 获取产品分类列表
export const getProductCategoryList = (params) => {
    return request({
        url: '/Product/GetProductCategoryList',
        method: 'POST',
        data: params
    })
}

// 获取产品信息列表
export const getProductList = (params) => {
    return request({
        url: '/Product/GetProductList',
        method: 'POST',
        data: params
    })
}