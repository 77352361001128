<script setup>
import { ref } from "vue"
import { getProductCategoryList } from "@/api/product"
import { message } from 'ant-design-vue';

const category = ref([])

getProductCategoryList({
    CurrentPage: 1,
    PageSize: 20
}).then(res => {
    category.value = res.Data;
})
</script>

<template>
    <footer class="page-footer">
        <div class="page-footer-content">
            <div class="left">
                <div class="footer-nav">
                    <div class="title">旗下产品</div>

                    <div class="nav-item" v-for="item in category" @click="$router.push('/product')">{{ item.Name }}</div>
                    <!-- <div class="nav-item">话费卡回收</div>
                    <div class="nav-item">话费卡回收</div>
                    <div class="nav-item">话费卡回收</div>
                    <div class="nav-item">话费卡回收</div>
                    <div class="nav-item">话费卡回收</div> -->
                </div>

                <div class="footer-nav">
                    <div class="title">声明与协议</div>

                    <div class="nav-item" @click="message.warning('敬请期待~')">服务协议</div>
                    <div class="nav-item" @click="message.warning('敬请期待~')">免责声明</div>
                    <div class="nav-item" @click="message.warning('敬请期待~')">隐私保护</div>
                    <div class="nav-item" @click="message.warning('敬请期待~')">寄售协议</div>
                </div>

                <div class="footer-nav">
                    <div class="title">合作洽谈</div>

                    <div class="nav-item" @click="message.warning('敬请期待~')">企业合作</div>
                    <div class="nav-item" @click="message.warning('敬请期待~')">代理商模式</div>
                </div>

                <div class="footer-nav">
                    <div class="title">联系我们</div>

                    <div class="nav-item">08:00-23:00</div>
                </div>
            </div>

            <div class="right">
                <div class="img-container"></div>
                <div>公众号</div>

                <div class="img-container" style="margin-top: 20px;"></div>
                <div>APP下载</div>
            </div>
        </div>

        <div class="copyright">
            深圳米特文化传媒有限公司
        </div>
    </footer>
</template>

<style lang="scss" scoped>
.page-footer {
    padding: 32px 0 20px 0;
    background: #F4F6F8;

    &-content {
        display: flex;
        margin: auto;
        max-width: 1440px;
        width: 100%;

        .left {
            flex: 1;
            display: flex;
            
            .footer-nav {
                flex: 1;
                text-align: left;

                .title {
                    margin-bottom: 16px;
                    font-size: 18px;
                    font-weight: bold;
                }

                .nav-item {
                    margin-top: 8px;
                    font-size: 15px;
                    color: #527595;
                    cursor: pointer;
                }
            }
        }

        .right {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 30%;
            
            .img-container {
                width: 120px;
                height: 120px;
                background: #fff;
            }

            &::after {
                position: absolute;
                content: "";
                display: block;
                width: 1px;
                height: 200px;
                left: 0;
                top: 0;
                background: #8AA2B8;
            }
        }
    }
}

.copyright {
    padding-top: 20px;
    margin-top: 32px;
    border-top: 1px solid #D9D9D9;
}
</style>