import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useUserStore } from "@/store/user"
import { message } from 'ant-design-vue';

// const userStore = useUserStore();

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/index.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/index.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "about" */ '../views/Product/index.vue')
  },
  {
    path: '/center',
    name: 'center',
    component: () => import(/* webpackChunkName: "about" */ '../views/Center/index.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderList/index.vue')
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import(/* webpackChunkName: "about" */ '../views/Verify/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


// 页面路由刚开始切换的时候
router.beforeEach(async (to, from, next) => {
  console.log(to);
  // 开启进度条
  NProgress.start()
  if(['order', 'verify', 'center'].includes(to.name)) {
    if(useUserStore().getToken || to.name == 'login' || to.name == 'register') {
      next()
    } else {
      message.warning('请先登陆')
      next('/login')
    }
  } else {
    next()
  }
})
 
// 页面路由切换完毕的时候
router.afterEach(() => {
  // 关闭进度条
  NProgress.done()
})

export default router
