import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {  //唯一命名
  state: () => ({
    userInfo: {
      Token: '', // 登陆token
    }
  }),
  getters: {
    getUserInfo: state => state.userInfo,
    getToken: state => state.userInfo.Token,
  },
  actions: {
    setUserInfo(info) {
      this.userInfo = {...this.userInfo, ...info}
    },
    async login(accounts) {
      this.setUserInfo({Token: window.btoa(accounts)})
    },
    logout() {
      this.userInfo = {
        Token: ''
      };
    }
  },
  persist: true
})